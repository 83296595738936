import { Component } from 'solid-js';
import MissingPage from '../components/MissingPage/MissingPage';


const Help: Component = () => {
  return (
    <>
      <MissingPage title="help" />
    </>
  );
}

export default Help;
