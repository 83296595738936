import { Component } from 'solid-js';
import MissingPage from '../components/MissingPage/MissingPage';


const Downloads: Component = () => {

  return (
    <>
      <MissingPage title="downloads" />
    </>
  );
}

export default Downloads;
